import { dispatch } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { FluxStandardAction } from "flux-standard-action";

// Flux-standard-action gives us stronger typing of our actions.
export type Payload = any;
export interface MetaData {}

export type LegacyQuoteEmailAction<
  T extends Payload = Payload
> = FluxStandardAction<string, T, MetaData>;

export enum LegacyQuoteEmailActionTypes {
  SEND_QUOTE_EMAIL = "[LegacyQuoteEmail] SEND_QUOTE_EMAIL",
  SEND_QUOTE_EMAIL_SUCCESS = "[LegacyQuoteEmail] SEND_QUOTE_EMAIL_SUCCESS",
  SEND_QUOTE_EMAIL_FAILED = "[LegacyQuoteEmail] SEND_QUOTE_EMAIL_FAILED",
  LOAD_DATA = "[LegacyQuoteEmail] LOAD_DATA",
  LOAD_DATA_SUCCESS = "[LegacyQuoteEmail] LOAD_DATA_SUCCESS",
  LOAD_DATA_FAILED = "[LegacyQuoteEmail] LOAD_DATA_FAILED"
}

@Injectable()
export class LegacyQuoteEmailActions {
  @dispatch()
  sendQuoteEmail = (payload: any): LegacyQuoteEmailAction => ({
    type: LegacyQuoteEmailActionTypes.SEND_QUOTE_EMAIL,
    payload
  });

  sendQuoteEmailSuccess = (payload: any): LegacyQuoteEmailAction => ({
    type: LegacyQuoteEmailActionTypes.SEND_QUOTE_EMAIL_SUCCESS,
    payload
  });

  sendQuoteEmailFailed = (error: any): LegacyQuoteEmailAction => ({
    type: LegacyQuoteEmailActionTypes.SEND_QUOTE_EMAIL_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  loadData = (payload: any): LegacyQuoteEmailAction => ({
    type: LegacyQuoteEmailActionTypes.LOAD_DATA,
    payload
  });

  loadDataSuccess = (payload: any): LegacyQuoteEmailAction => ({
    type: LegacyQuoteEmailActionTypes.LOAD_DATA_SUCCESS,
    payload
  });

  loadDataFailed = (error: any): LegacyQuoteEmailAction => ({
    type: LegacyQuoteEmailActionTypes.LOAD_DATA_FAILED,
    payload: error,
    error: true
  });
}
