// import { Customer } from "@flightpath/ui";
import { InquiryOutput } from "@flightpath/api-types";
import { Sorting, SortType } from "@flightpath/ui";
import * as moment from "moment";
import { FlightListFilter } from "../flight-list/model";
import { NoteAction, NoteActionTypes } from "../notes/actions";
import { CustomerAction, CustomerActionTypes } from "./actions";
import { Customer, CustomerFormData, CustomerState } from "./model";

const INITIAL_STATE: CustomerState = {
  customer: null,
  formData: {},
  error: undefined,
  existingCustomers: [],
  profile: {
    customerNotes: [],
    customerStats: null,
    inquiryContactEvents: []
  },
  flights: {
    items: [],
    filteredItems: [],
    filter: { tripStatus: "Confirmed" },
    sorting: {
      field: "EnquiryReceivedDate",
      direction: -1,
      type: SortType.Date
    },
    loading: false,
    error: undefined
  }
};

const sortingFields = [
  "flightAdviser",
  "size",
  "flightDate",
  "originAirportCode",
  "destinationAirportCode",
  "customer",
  "bids",
  "lowestBidDollar",
  "latestBidDate"
];

function strNormalize(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\W+/g, "")
    .toLowerCase()
    .trim();
}

export function flightListFilter(filter) {
  return function(item: InquiryOutput) {
    let include = true;
    if (filter.tripStatus) {
      include =
        include &&
        strNormalize(item.status || "").indexOf(strNormalize(filter.tripStatus)) >= 0;
    }
    return include;
  };
}

function defaultFlightListSort(a: InquiryOutput, b: InquiryOutput) {
  return moment(b.receivedDate).diff(moment(a.receivedDate), "ms");
}

function flightListSorter(sorting: Sorting) {
  if (!sorting) return defaultFlightListSort;
  return function(a: InquiryOutput, b: InquiryOutput) {
    if (sortingFields.includes(sorting.field)) {
      switch (sorting.field) {
        case "flightAdviser":
          return (
            ((a.advisor.name as string) || "")
              .toString()
              .localeCompare(((b.advisor.name as string) || "").toString()) *
            sorting.direction
          );
        case "size":
          return (
            ((a.legs[0].jetCategory[0] as string) || "")
              .toString()
              .localeCompare(
                ((b.legs[0].jetCategory[0] as string) || "").toString()
              ) * sorting.direction
          );
        case "flightDate":
          return (
            moment(a.legs[0].departureDate).diff(
              moment(b.legs[0].departureDate),
              "ms"
            ) * sorting.direction
          );
        case "originAirportCode":
          return (
            ((a.legs[0].origin.icao as string) || "")
              .toString()
              .localeCompare(
                ((b.legs[0].origin.icao as string) || "").toString()
              ) * sorting.direction
          );
        case "destinationAirportCode":
          return (
            ((a.legs[0].destination.icao as string) || "")
              .toString()
              .localeCompare(
                ((b.legs[0].destination.icao as string) || "").toString()
              ) * sorting.direction
          );
        case "customer":
          return (
            ((a.customer.name as string) || "")
              .toString()
              .localeCompare(((b.customer.name as string) || "").toString()) *
            sorting.direction
          );
        case "bids":
          return (
            (((a.bidInfo && a.bidInfo.returnedCount) || 0) -
              ((b.bidInfo && b.bidInfo.returnedCount) || 0)) *
            sorting.direction
          );
        case "lowestBidDollar":
          return (
            (((a.bidInfo && a.bidInfo.lowestDollar) || 0) -
              ((b.bidInfo && b.bidInfo.lowestDollar) || 0)) *
            sorting.direction
          );
        case "latestBidDate":
          return (
            moment((a.bidInfo && a.bidInfo.latestDate) || null).diff(
              moment((b.bidInfo && b.bidInfo.latestDate) || null),
              "ms"
            ) * sorting.direction
          );
      }
    } else {
      switch (sorting.type) {
        case SortType.Text:
          return (
            ((a[sorting.field] as string) || "")
              .toString()
              .localeCompare(((b[sorting.field] as string) || "").toString()) *
            sorting.direction
          );
        case SortType.Number:
          return (
            ((a[sorting.field] || 0) - (b[sorting.field] || 0)) *
            sorting.direction
          );
        case SortType.Date:
          return (
            moment(a[sorting.field]).diff(moment(b[sorting.field]), "ms") *
            sorting.direction
          );
        default:
          return 1;
      }
    }
  };
}

export function customerReducer(
  state = INITIAL_STATE,
  action: CustomerAction
): CustomerState {
  switch (action.type) {
    case CustomerActionTypes.LOAD_CREATE_CUSTOMER_FORM_DATA:
      return {
        ...state
      };
    case CustomerActionTypes.LOAD_CREATE_CUSTOMER_FORM_DATA_SUCCESS:
      return {
        ...state,
        formData: action.payload as CustomerFormData
      };
    case CustomerActionTypes.LOAD_CREATE_CUSTOMER_FORM_DATA_FAILED:
      return {
        ...state,
        error: action.error
      };
    case CustomerActionTypes.SAVE_CUSTOMER:
      return {
        ...state
      };
    case CustomerActionTypes.SAVE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload as Customer,
        existingCustomers: []
      };
    case CustomerActionTypes.SAVE_CUSTOMER_FAILED:
      return {
        ...state,
        customer: null,
        error: action.error
      };
    case CustomerActionTypes.SEARCH_EXISTING_CUSTOMERS:
      return {
        ...state
      };
    case CustomerActionTypes.SEARCH_EXISTING_CUSTOMERS_SUCCESS:
      return {
        ...state,
        existingCustomers: action.payload as Customer[]
      };
    case CustomerActionTypes.SEARCH_EXISTING_CUSTOMERS_FAILED:
      return {
        ...state,
        existingCustomers: [],
        error: action.error
      };
    case CustomerActionTypes.UPDATE_CUSTOMER:
      return {
        ...state
      };
    case CustomerActionTypes.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload as Customer,
        existingCustomers: []
      };
    case CustomerActionTypes.UPDATE_CUSTOMER_FAILED:
      return {
        ...state,
        customer: null,
        error: action.error
      };
    case CustomerActionTypes.LOAD_CUSTOMER:
      return {
        ...state
      };
    case CustomerActionTypes.LOAD_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload as Customer,
        existingCustomers: []
      };
    case CustomerActionTypes.LOAD_CUSTOMER_FAILED:
      return {
        ...state,
        customer: null,
        error: action.error
      };
    case CustomerActionTypes.LOAD_CUSTOMER_FLIGHTS:
      return {
        ...state
      };
    case CustomerActionTypes.LOAD_CUSTOMER_FLIGHTS_SUCCESS:
      const profileData = action.payload as any;
      const items = profileData.Flights as InquiryOutput[];
      const filteredItems = items
        .filter(flightListFilter(state.flights.filter))
        .sort(flightListSorter(state.flights.sorting));
      return {
        ...state,
        profile: {
          ...state.profile,
          customerNotes: [...profileData.CustomerNotes],
          customerStats: { ...profileData.CustomerStats }
        },
        flights: {
          ...state.flights,
          filteredItems,
          items,
          loading: false,
          error: undefined
        }
      };
    case CustomerActionTypes.LOAD_CUSTOMER_FLIGHTS_FAILED:
      return {
        ...state,
        profile: {
          ...state.profile,
          customerNotes: [],
          customerStats: null,
          inquiryContactEvents: []
        },
        flights: { ...INITIAL_STATE.flights },
        error: action.error
      };
    case CustomerActionTypes.CUSTOMER_FLIGHTS_FILTER: {
      const filter = {
        ...state.flights.filter,
        ...(action.payload as FlightListFilter)
      };
      const filteredItems = state.flights.items
        .filter(flightListFilter(filter))
        .sort(flightListSorter(state.flights.sorting));
      return {
        ...state,
        flights: {
          ...state.flights,
          filter,
          filteredItems,
          loading: false,
          error: undefined
        }
      };
    }
    case CustomerActionTypes.CUSTOMER_FLIGHTS_SORT: {
      const sorting = {
        ...(action.payload as Sorting)
      };
      const filteredItems = state.flights.items
        .filter(flightListFilter(state.flights.filter))
        .sort(flightListSorter(sorting));
      return {
        ...state,
        flights: {
          ...state.flights,
          filteredItems,
          sorting,
          loading: false,
          error: undefined
        }
      };
    }
    case CustomerActionTypes.LOAD_CUSTOMER_PROFILE:
      return {
        ...state
      };
    case CustomerActionTypes.LOAD_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          customer: action.payload as Customer
        }
      };
    case CustomerActionTypes.LOAD_CUSTOMER_PROFILE_FAILED:
      return {
        ...state,
        profile: null,
        error: action.error
      };
    case NoteActionTypes.SAVE_NOTE_FROM_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          [(action as NoteAction).meta.noteType]: [
            ...state.profile[(action as NoteAction).meta.noteType],
            action.payload
          ]
        }
      };
    case CustomerActionTypes.SAVE_OUTREACH_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          inquiryContactEvents: [
            ...state.profile.inquiryContactEvents,
            action.payload as any
          ]
        }
      };
    case CustomerActionTypes.SAVE_OUTREACH_FAILED:
      return {
        ...state,
        error: action.error
      };
    case CustomerActionTypes.SAVE_CUSTOMER_AND_CREATE_INQUIRY:
      return {
        ...state
      };
    case CustomerActionTypes.SAVE_CUSTOMER_AND_CREATE_INQUIRY_SUCCESS:
      return {
        ...state,
        customer: action.payload as Customer,
        existingCustomers: []
      };
    case CustomerActionTypes.SAVE_CUSTOMER_AND_CREATE_INQUIRY_FAILED:
      return {
        ...state,
        customer: null,
        error: action.error
      };
  }
  return state;
}
