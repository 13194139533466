import { Injectable } from "@angular/core";
import { Auth, InitialAuthState } from "../auth/model";
import { CustomerState } from "../customer/model";
import { FlightDetailsState } from "../flight-details/model";
import { FlightList } from "../flight-list/model";

export type AppState = Partial<{
  auth: Auth;
  flights: FlightList;
  flightDetails: FlightDetailsState;
  customer: CustomerState;
  itinerary: any;
  routes: string;
  routeData: any;
}>;

@Injectable()
export class InitialAppState {
  constructor(private authState: InitialAuthState) {}

  createState() {
    return {
      auth: this.authState.createState()
    };
  }
}
