import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlightPathUIModule } from "@flightpath/ui";
import { NgxMaskModule } from "ngx-mask";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./auth/auth.module";
import { LoginModule } from "./login/login.module";
import { NotesModule } from "./notes/notes.module";
import { SharedModule } from "./shared/shared.module";
import { PhoneValidator } from "./shared/validators/phone-validator.directive";
import { StoreModule } from "./store/store.module";

@NgModule({
  declarations: [AppComponent, PhoneValidator],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    NotesModule,
    FlightPathUIModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    LoginModule,
    StoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
