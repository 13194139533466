import { Directive, Input } from "@angular/core";
import {
  AbstractControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn
} from "@angular/forms";

export function phoneValidator(required: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value && required) {
      return { phoneIsRequired: { value: true } };
    }

    if (control.value && control.value.Number) {
      const numberIsValid = new RegExp("^[0-9]{6,14}$").test(
        control.value.Number
      );
      if (!numberIsValid) {
        return { notValidNumber: { value: control.value.Number } };
      }
    }

    if (
      control.value &&
      (control.value.Number === null || control.value.Number === "") &&
      control.value.DialingCode === null
    ) {
      return { phoneIsRequired: { value: true } };
    }

    if (
      control.value &&
      control.value.Number &&
      control.value.DialingCode === null
    ) {
      return { codeIsRequired: { value: true } };
    }

    if (
      control.value &&
      control.value.DialingCode &&
      (control.value.Number === null || control.value.Number === "")
    ) {
      return { numberIsRequired: { value: true } };
    }

    return null;
  };
}

@Directive({
  selector: "[phoneValidator]",
  providers: [
    { provide: NG_VALIDATORS, useExisting: PhoneValidator, multi: true }
  ]
})
export class PhoneValidator implements Validator {
  @Input("required")
  required: boolean;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return phoneValidator(this.required)(control);
  }
}
