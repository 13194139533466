import { composeReducers, defaultFormReducer } from "@angular-redux/form";
import { routerReducer } from "@angular-redux/router";
import { combineReducers } from "redux";
import { authReducer } from "../auth/reducer";
import { customerReducer } from "../customer/reducer";
import { flightDetailsReducer } from "../flight-details/reducer";
import { flightListReducer } from "../flight-list/reducer";
import { itineraryReducer } from "../itinerary/reducer";
import { legacyQuoteEmailReducer } from "../legacy-quote-email/reducer";
import { RootAction, RootActionTypes } from "./actions";

export interface RouteInitialState {
  params: any;
  data: any;
}

const ROUTE_INITIAL_STATE: RouteInitialState = {
  params: {},
  data: {}
};

export function routeReducer(
  state = ROUTE_INITIAL_STATE,
  action: RootAction
): RouteInitialState {
  switch (action.type) {
    case RootActionTypes.ROUTE_DATA:
      return { ...state, data: { ...action.payload } };
    case RootActionTypes.ROUTE_PARAMS:
      return { ...state, params: { ...action.payload } };
  }

  return state;
}

// Define the global store shape by combining our application's
// reducers together into a given structure.
export const rootReducer = composeReducers(
  defaultFormReducer<unknown>(),
  combineReducers({
    auth: authReducer,
    flights: flightListReducer,
    router: routerReducer,
    route: routeReducer,
    flightDetails: flightDetailsReducer,
    customer: customerReducer,
    itinerary: itineraryReducer,
    quoteEmail: legacyQuoteEmailReducer
  })
);
