import { LegacyQuoteEmailAction, LegacyQuoteEmailActionTypes } from "./actions";

const INITIAL_STATE = {
  details: null
};

export function legacyQuoteEmailReducer(
  state = INITIAL_STATE,
  action: LegacyQuoteEmailAction
) {
  switch (action.type) {
    case LegacyQuoteEmailActionTypes.LOAD_DATA:
      return {
        ...state
      };
    case LegacyQuoteEmailActionTypes.LOAD_DATA_SUCCESS:
      return {
        details: action.payload
      };
    case LegacyQuoteEmailActionTypes.LOAD_DATA_FAILED:
      return {
        details: null
      };
  }

  return state;
}
