import { select } from "@angular-redux/store";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthActions } from "../auth/actions";

@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
  @select(["auth", "error"])
  readonly error!: Observable<boolean>;

  @select(["auth", "locked"])
  readonly locked!: Observable<boolean>;

  username: string = "";
  password: string = "";
  formIsSubmitted: boolean = false;

  constructor(private actions: AuthActions, private router: Router) {}

  login(form) {
    this.formIsSubmitted = true;
    if (form.valid) {
      this.actions.login(this.username, this.password);
    }
  }

  navigateToFP() {
    this.router.navigate(["/forgotten-password"]);
  }
}
