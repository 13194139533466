import { InquiryOutput } from "@flightpath/api-types";
import { ItineraryAction, ItineraryActionTypes } from "./actions";

const INITIAL_STATE = {
  inquiry: null,
  airportOptions: [],
  loading: false,
  error: undefined
};

export function itineraryReducer(
  state = INITIAL_STATE,
  action: ItineraryAction
): any {
  switch (action.type) {
    case ItineraryActionTypes.INIT_ITINERARY:
      return {
        ...state,
        inquiry: {
          customerId: action.payload
        },
        loading: true,
        error: undefined
      };
    case ItineraryActionTypes.INIT_ITINERARY_SUCCESS:
      return {
        ...state,
        inquiry: {
          ...(action.payload as InquiryOutput)
        },
        loading: true,
        error: undefined
      };
    case ItineraryActionTypes.INIT_ITINERARY_FAILED:
      return {
        ...INITIAL_STATE,
        loading: false,
        error: action.error
      };
    case ItineraryActionTypes.SAVE_ITINERARY:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case ItineraryActionTypes.SAVE_ITINERARY_SUCCESS: {
      return {
        ...state,
        inquiry: {
          ...(action.payload as InquiryOutput)
        },
        loading: false,
        error: undefined
      };
    }
    case ItineraryActionTypes.SAVE_ITINERARY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ItineraryActionTypes.LOAD_ITINERARY:
      return {
        ...state,
        inquiry: null,
        loading: true,
        error: undefined
      };
    case ItineraryActionTypes.LOAD_ITINERARY_SUCCESS:
      return {
        ...state,
        inquiry: {
          ...(action.payload as InquiryOutput)
        },
        loading: true,
        error: undefined
      };
    case ItineraryActionTypes.LOAD_ITINERARY_FAILED:
      return {
        ...INITIAL_STATE,
        loading: false,
        error: action.error
      };
    case ItineraryActionTypes.SAVE_ITINERARY_EXTRA_DETAILS:
      return {
        ...state,
        inquiry: null,
        loading: true,
        error: undefined
      };
    case ItineraryActionTypes.SAVE_ITINERARY_EXTRA_DETAILS_SUCCESS:
      return {
        ...state,
        inquiry: {
          ...(action.payload as InquiryOutput)
        },
        loading: true,
        error: undefined
      };
    case ItineraryActionTypes.SAVE_ITINERARY_EXTRA_DETAILS_FAILED:
      return {
        ...INITIAL_STATE,
        loading: false,
        error: action.error
      };
  }

  return state;
}
