import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { JwtModule } from "@auth0/angular-jwt";
import { AuthActions } from "./actions";
import { AuthGuard } from "./auth.guard";
import { AuthEpics } from "./epics";
import { InitialAuthState } from "./model";
import { AuthService, tokenGetter } from "./service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        authScheme: ""
      }
    })
  ],
  providers: [AuthGuard, AuthActions, AuthEpics, AuthService, InitialAuthState]
})
export class AuthModule {}
