import { NgReduxRouterModule } from "@angular-redux/router";
import { NgReduxModule } from "@angular-redux/store";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { ForgottenPasswordComponent } from "./login/forgotten-password.component";
import { LoginComponent } from "./login/login.component";
import { LoginGuard } from "./login/login.guard";

const routes: Routes = [
  {
    path: "flight-list",
    loadChildren: () =>
      import("./flight-list/flight-list.module").then(m => m.FlightListModule),
    canActivate: [AuthGuard]
  },
  {
    path: "flight-details",
    loadChildren: () =>
      import("./flight-details/flight-details.module").then(
        m => m.FlightDetailsModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: "customer",
    loadChildren: () =>
      import("./customer/customer.module").then(m => m.CustomerModule),
    canActivate: [AuthGuard]
  },
  {
    path: "itinerary",
    loadChildren: () =>
      import("./itinerary/itinerary.module").then(m => m.ItineraryModule),
    canActivate: [AuthGuard]
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./profile/profile.module").then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: "send-quote",
    loadChildren: () =>
      import("./legacy-quote-email/legacy-quote-email.module").then(
        m => m.LegacyQuoteEmailModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [LoginGuard],
    data: {
      title: "",
      hideNavbar: true
    }
  },
  {
    path: "forgotten-password",
    component: ForgottenPasswordComponent,
    canActivate: [LoginGuard],
    data: {
      title: "",
      hideNavbar: true
    }
  },
  {
    path: "",
    redirectTo: "flight-list",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    NgReduxModule,
    NgReduxRouterModule.forRoot()
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
