import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LoaderService } from "../shared/loader.service";
import { User } from "./model";

export function tokenGetter() {
  return localStorage.getItem("token");
}

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private loaderService: LoaderService
  ) {}

  get currentUser() {
    const token = tokenGetter();
    if (!token) return null;
    return this.jwtHelper.decodeToken(token);
  }

  login(username: string, password: string): Observable<User> {
    this.loaderService.show();
    return this.http.post<User>("user/login", { username, password }).pipe(
      map(res => {
        this.loaderService.hide();
        return res;
      })
    );
  }
}
