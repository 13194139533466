import { dispatch } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { InquiryOutput, InquiryUpdateInput } from "@flightpath/api-types";
import { FluxStandardAction } from "flux-standard-action";

export interface MetaData {}

// Flux-standard-action gives us stronger typing of our actions.
export type Payload = InquiryOutput | any;

export type ItineraryAction<T extends Payload = Payload> = FluxStandardAction<
  string,
  T,
  MetaData
>;

export enum ItineraryActionTypes {
  INIT_ITINERARY = "[Itinerary] INIT_ITINERARY",
  INIT_ITINERARY_SUCCESS = "[Itinerary] INIT_ITINERARY_SUCCESS",
  INIT_ITINERARY_FAILED = "[Itinerary] INIT_ITINERARY_FAILED",
  SAVE_ITINERARY = "[Itinerary] SAVE_ITINERARY",
  SAVE_ITINERARY_SUCCESS = "[Itinerary] SAVE_ITINERARY_SUCCESS",
  SAVE_ITINERARY_FAILED = "[Itinerary] SAVE_ITINERARY_FAILED",
  LOAD_ITINERARY = "[Itinerary] LOAD_ITINERARY",
  LOAD_ITINERARY_SUCCESS = "[Itinerary] LOAD_ITINERARY_SUCCESS",
  LOAD_ITINERARY_FAILED = "[Itinerary] LOAD_ITINERARY_FAILED",
  SAVE_ITINERARY_EXTRA_DETAILS = "[Itinerary] SAVE_ITINERARY_EXTRA_DETAILS",
  SAVE_ITINERARY_EXTRA_DETAILS_SUCCESS = "[Itinerary] SAVE_ITINERARY_EXTRA_DETAILS_SUCCESS",
  SAVE_ITINERARY_EXTRA_DETAILS_FAILED = "[Itinerary] SAVE_ITINERARY_EXTRA_DETAILS_FAILED"
}

@Injectable()
export class ItineraryActions {
  @dispatch()
  initItinerary = (customerId: string): ItineraryAction => ({
    type: ItineraryActionTypes.INIT_ITINERARY,
    payload: customerId
  });

  initItinerarySuccess = (payload: InquiryOutput): ItineraryAction => ({
    type: ItineraryActionTypes.INIT_ITINERARY_SUCCESS,
    payload
  });

  initItineraryFailed = (error): ItineraryAction => ({
    type: ItineraryActionTypes.INIT_ITINERARY_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  saveItinerary = (itinerary: InquiryUpdateInput): ItineraryAction => ({
    type: ItineraryActionTypes.SAVE_ITINERARY,
    payload: itinerary
  });

  saveItinerarySuccess = (payload: InquiryOutput): ItineraryAction => ({
    type: ItineraryActionTypes.SAVE_ITINERARY_SUCCESS,
    payload
  });

  saveItineraryFailed = (error): ItineraryAction => ({
    type: ItineraryActionTypes.SAVE_ITINERARY_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  loadItinerary = (itineraryId: string): ItineraryAction => ({
    type: ItineraryActionTypes.LOAD_ITINERARY,
    payload: itineraryId
  });

  loadItinerarySuccess = (payload: InquiryOutput): ItineraryAction => ({
    type: ItineraryActionTypes.LOAD_ITINERARY_SUCCESS,
    payload
  });

  loadItineraryFailed = (error): ItineraryAction => ({
    type: ItineraryActionTypes.LOAD_ITINERARY_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  saveItineraryExtraDetails = (
    itinerary: InquiryUpdateInput
  ): ItineraryAction => ({
    type: ItineraryActionTypes.SAVE_ITINERARY_EXTRA_DETAILS,
    payload: itinerary
  });

  saveItineraryExtraDetailsSuccess = (
    payload: InquiryOutput
  ): ItineraryAction => ({
    type: ItineraryActionTypes.SAVE_ITINERARY_EXTRA_DETAILS_SUCCESS,
    payload
  });

  saveItineraryExtraDetailsFailed = (error): ItineraryAction => ({
    type: ItineraryActionTypes.SAVE_ITINERARY_EXTRA_DETAILS_FAILED,
    payload: error,
    error: true
  });
}
