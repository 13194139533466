import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { combineEpics, Epic } from "redux-observable";
import { empty, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AppState } from "../store/model";
import { AuthAction, AuthActions, Payload } from "./actions";
import { AuthService } from "./service";

@Injectable()
export class AuthEpics {
  constructor(
    private service: AuthService,
    private actions: AuthActions,
    private router: Router
  ) {}

  createEpic() {
    return combineEpics(this.createLoginEpic(), this.createLogoutEpic());
  }

  private createLoginEpic(): Epic<
    AuthAction<Payload>,
    AuthAction<Payload>,
    AppState
  > {
    return (action$, state$) =>
      action$.ofType(AuthActions.LOGIN).pipe(
        switchMap(action =>
          this.service.login(action.meta.username, action.meta.password).pipe(
            map(user => {
              localStorage.setItem("token", user.token);
              const redirectUrl = sessionStorage.getItem("redirectUrl");
              sessionStorage.removeItem("redirectUrl");
              if (redirectUrl) this.router.navigateByUrl(redirectUrl);
              else this.router.navigate(["/flight-list"]);
              let u = this.service.currentUser;
              return this.actions.loginSuccess(u.user);
            }),
            catchError(response =>
              of(
                this.actions.loginFailed({
                  status: response.status,
                  locked: response.status == 403
                })
              )
            )
          )
        )
      );
  }

  private createLogoutEpic(): Epic<
    AuthAction<Payload>,
    AuthAction<Payload>,
    AppState
  > {
    return (action$, state$) =>
      action$.ofType(AuthActions.LOGOUT).pipe(
        switchMap(action => {
          localStorage.removeItem("token");
          this.router.navigate(["/login"]);
          return empty();
        })
      );
  }
}
