import { dispatch } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { Note, NoteData } from "@flightpath/ui";
import { FluxStandardAction } from "flux-standard-action";
import { NoteError, NoteType } from "./model";

export interface MetaData {
  noteType?: NoteType;
}

// Flux-standard-action gives us stronger typing of our actions.
export type Payload = NoteData | Note | NoteError;

export type NoteAction<T extends Payload = Payload> = FluxStandardAction<
  string,
  T,
  MetaData
>;

export enum NoteActionTypes {
  SAVE_NOTE = "[Notes] SAVE_NOTE",
  SAVE_NOTE_SUCCESS = "[Notes] SAVE_NOTE_SUCCESS",
  SAVE_FAILED = "[Notes] SAVE_FAILED",
  SAVE_NOTE_FROM_PROFILE = "[Notes] SAVE_NOTE_FROM_PROFILE",
  SAVE_NOTE_FROM_PROFILE_SUCCESS = "[Notes] SAVE_NOTE_FROM_PROFILE_SUCCESS",
  SAVE_NOTE_FROM_PROFILE_FAILED = "[Notes] SAVE_NOTE_FROM_PROFILE_FAILED"
}

@Injectable()
export class NoteActions {
  @dispatch()
  saveNote = (payload: NoteData, noteType: NoteType): NoteAction => ({
    type: NoteActionTypes.SAVE_NOTE,
    payload,
    meta: { noteType }
  });

  saveNoteSuccess = (payload: Note, noteType: NoteType): NoteAction => ({
    type: NoteActionTypes.SAVE_NOTE_SUCCESS,
    payload,
    meta: { noteType }
  });

  saveFailed = (error: NoteError): NoteAction => ({
    type: NoteActionTypes.SAVE_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  saveNoteFromProfile = (
    payload: NoteData,
    noteType: NoteType
  ): NoteAction => ({
    type: NoteActionTypes.SAVE_NOTE_FROM_PROFILE,
    payload,
    meta: { noteType }
  });

  saveNoteFromProfileSuccess = (
    payload: Note,
    noteType: NoteType
  ): NoteAction => ({
    type: NoteActionTypes.SAVE_NOTE_FROM_PROFILE_SUCCESS,
    payload,
    meta: { noteType }
  });

  saveFromProfileFailed = (error: NoteError): NoteAction => ({
    type: NoteActionTypes.SAVE_NOTE_FROM_PROFILE_FAILED,
    payload: error,
    error: true
  });
}
