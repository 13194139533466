import { select } from "@angular-redux/store";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { GridMenuItem } from "@flightpath/ui";
import { combineLatest, Observable, Subscription } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import { AuthActions } from "./auth/actions";
import { RootActions } from "./store/actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  @select(["sockets", "notifications"])
  readonly notifications!: Observable<any>;

  @select(["auth", "user"])
  readonly user!: Observable<any>;

  title: string;
  hideNavbar: boolean;
  user$: Subscription;

  accountMenuItems: GridMenuItem[] = [
    {
      label: "My Account",
      iconClass: "male",
      identifier: "my-account"
    },
    {
      label: "Logout",
      iconClass: "logout2",
      identifier: "logout"
    }
  ];

  waffleMenuItems: GridMenuItem[] = [
    {
      label: "Customers",
      iconClass: "colleagues",
      identifier: "customers"
    },
    {
      label: "Flights Overview",
      iconClass: "onesky_logo",
      identifier: "flightsOverview"
    },
    {
      label: "Airport Directory",
      iconClass: "jet_2",
      identifier: "airportDirectory"
    }
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private actions: RootActions,
    private titleService: Title,
    private authActions: AuthActions
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => combineLatest(route.data, route.params))
      )
      .subscribe(([data, params]) => {
        this.actions.setRouteParams(params);
        this.actions.setRouteData(data);
        this.title =
          typeof data.title == "function"
            ? data.title(data, params)
            : data.title;
        if (this.title) {
          this.titleService.setTitle(`FlightPath - ${this.title}`);
        } else {
          this.titleService.setTitle(`FlightPath`);
        }
        if (data && data.hideNavbar) {
          this.hideNavbar = true;
        } else {
          this.hideNavbar = false;
        }
      });
  }

  ngOnInit() {
    this.user$ = this.user.subscribe(user => {
      /*       if (user) {
        this.socketActions.getNotification();

        let timerId = setInterval(
          () => this.socketActions.getNotification(),
          5000
        );
        // after 5 seconds stop
        setTimeout(() => {
          clearInterval(timerId);
        }, 15000);
      } */
    });
  }

  gridItemClicked(itemIdentifier: string) {
    switch (itemIdentifier) {
      case "logout":
        this.authActions.logout();
        break;
      case "my-account":
        this.router.navigate(["profile"]);
        break;
      case "customers":
        this.router.navigate(["customer"]);
        break;
      case "flightsOverview":
        this.router.navigate(["flight-list"]);
        break;
      default:
        break;
    }
  }
}
