import { Note } from "@flightpath/ui";

export interface NoteError {
  status: string;
}

export enum NoteType {
  TripNote = "InquiryNotes",
  CustomerNote = "CustomerNotes"
}

export interface NoteResponse {
  result: Note;
}
