import { InquiryOutput } from "@flightpath/api-types";
import { Sorting, SortType } from "@flightpath/ui";
import * as moment from "moment";
import { FlightListAction, FlightListActionTypes } from "./actions";
import { FlightList, FlightListFilter } from "./model";

const INITIAL_STATE: FlightList = {
  items: [],
  filteredItems: [],
  filter: {
    isVisible: true
  },
  sorting: {
    field: "receivedDate",
    direction: -1,
    type: SortType.Date
  },
  loading: false,
  error: undefined
};

const sortingFields = [
  "flightAdviser",
  "size",
  "flightDate",
  "originAirportCode",
  "destinationAirportCode",
  "customer",
  "bids",
  "lowestBidDollar",
  "latestBidDate"
];

function strNormalize(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\W+/g, "")
    .toLowerCase()
    .trim();
}

export function flightListFilter(filter: FlightListFilter) {
  return function(item: InquiryOutput) {
    let include = true;
    if (filter.startDate) {
      include =
        include &&
        moment(item.legs[0].departureDate).isAfter(filter.startDate, "day");
    }
    if (filter.endDate) {
      include =
        include &&
        moment(item.legs[0].departureDate).isBefore(filter.endDate, "day");
    }
    if (filter.tripStatus) {
      include =
        include &&
        strNormalize(item.status || "").indexOf(
          strNormalize(filter.tripStatus)
        ) >= 0;
    }
    if (filter.search) {
      include =
        include &&
        strNormalize(
          `${item.id || ""}
          ${item.legs[0].origin.iata || ""}
          ${item.legs[0].origin.icao || ""}
          ${item.legs[0].origin.city || ""}
          ${item.legs[item.legs.length - 1].destination.iata || ""}
          ${item.legs[item.legs.length - 1].destination.icao || ""}
          ${item.legs[item.legs.length - 1].destination.city || ""}
          ${item.customer && item.customer.name ? item.customer.name : ""}
          ${
            item.customer && item.customer.organization
              ? item.customer.organization
              : ""
          }`
        ).indexOf(strNormalize(filter.search)) >= 0;
    }
    return include;
  };
}

function defaultFlightListSort(a: InquiryOutput, b: InquiryOutput) {
  return moment(b.receivedDate).diff(moment(a.receivedDate), "ms");
}

function flightListSorter(sorting: Sorting) {
  if (!sorting) return defaultFlightListSort;
  return function(a: InquiryOutput, b: InquiryOutput) {
    if (sortingFields.includes(sorting.field)) {
      switch (sorting.field) {
        case "flightAdviser":
          return (
            (a.advisor && a.advisor.name ? (a.advisor.name as string) : "")
              .toString()
              .localeCompare(
                (b.advisor && b.advisor.name
                  ? (b.advisor.name as string)
                  : ""
                ).toString()
              ) * sorting.direction
          );
        case "size":
          return (
            ((a.legs[0].jetCategory[0] as string) || "")
              .toString()
              .localeCompare(
                ((b.legs[0].jetCategory[0] as string) || "").toString()
              ) * sorting.direction
          );
        case "flightDate":
          return (
            moment(a.legs[0].departureDate).diff(
              moment(b.legs[0].departureDate),
              "ms"
            ) * sorting.direction
          );
        case "originAirportCode":
          return (
            ((a.legs[0].origin.icao as string) || "")
              .toString()
              .localeCompare(
                ((b.legs[0].origin.icao as string) || "").toString()
              ) * sorting.direction
          );
        case "destinationAirportCode":
          return (
            ((a.legs[0].destination.icao as string) || "")
              .toString()
              .localeCompare(
                ((b.legs[0].destination.icao as string) || "").toString()
              ) * sorting.direction
          );
        case "customer":
          return (
            (a.customer && a.customer.name ? a.customer.name as string : "")
              .toString()
              .localeCompare((b.customer && b.customer.name ? b.customer.name as string : "").toString()) *
            sorting.direction
          );
        case "bids":
          return (
            ((a.bidInfo && a.bidInfo.returnedCount ? a.bidInfo.returnedCount : 0) -
              (b.bidInfo && b.bidInfo.returnedCount ? b.bidInfo.returnedCount : 0)) *
            sorting.direction
          );
        case "lowestBidDollar":
          return (
            ((a.bidInfo && a.bidInfo.lowestDollar ? a.bidInfo.lowestDollar : 0) -
              (b.bidInfo && b.bidInfo.lowestDollar ? b.bidInfo.lowestDollar : 0)) *
            sorting.direction
          );
        case "latestBidDate":
          return (
            moment(a.bidInfo && a.bidInfo.latestDate ? a.bidInfo.latestDate : null).diff(
              moment(b.bidInfo && b.bidInfo.latestDate ? b.bidInfo.latestDate : null),
              "ms"
            ) * sorting.direction
          );
      }
    } else {
      switch (sorting.type) {
        case SortType.Text:
          return (
            ((a[sorting.field] as string) || "")
              .toString()
              .localeCompare(((b[sorting.field] as string) || "").toString()) *
            sorting.direction
          );
        case SortType.Number:
          return (
            ((a[sorting.field] || 0) - (b[sorting.field] || 0)) *
            sorting.direction
          );
        case SortType.Date:
          return (
            moment(a[sorting.field]).diff(moment(b[sorting.field]), "ms") *
            sorting.direction
          );
        default:
          return 1;
      }
    }
  };
}

export function flightListReducer(
  state: FlightList = INITIAL_STATE,
  action: FlightListAction
): FlightList {
  switch (action.type) {
    case FlightListActionTypes.LOAD:
      return {
        ...state,
        loading: !action.meta.isBackground && state.items.length == 0,
        error: undefined
      };
    case FlightListActionTypes.LOAD_SUCCESS: {
      const items = action.payload as InquiryOutput[];
      const filteredItems = items
        .filter(flightListFilter(state.filter))
        .sort(flightListSorter(state.sorting));
      return {
        ...state,
        filteredItems,
        items,
        loading: false,
        error: undefined
      };
    }
    case FlightListActionTypes.LOAD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case FlightListActionTypes.FILTER: {
      const filter = {
        ...state.filter,
        ...(action.payload as FlightListFilter)
      };
      let filteredItems;
      if (
        !filter.endDate &&
        !filter.startDate &&
        !filter.tripStatus &&
        !filter.search
      ) {
        filteredItems = state.items.sort(flightListSorter(state.sorting));
      } else {
        filteredItems = state.items
          .filter(flightListFilter(filter))
          .sort(flightListSorter(state.sorting));
      }
      return {
        ...state,
        filter,
        filteredItems,
        loading: false,
        error: undefined
      };
    }
    case FlightListActionTypes.FILTER_TOGGLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          isVisible: !state.filter.isVisible
        }
      };
    case FlightListActionTypes.SORT: {
      const sorting = {
        ...(action.payload as Sorting)
      };
      const filteredItems = state.items
        .filter(flightListFilter(state.filter))
        .sort(flightListSorter(sorting));
      return {
        ...state,
        filteredItems,
        sorting,
        loading: false,
        error: undefined
      };
    }
  }

  return state;
}
