import { dispatch } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { InquiryOutput } from "@flightpath/api-types";
import { Sorting } from "@flightpath/ui";
import { FluxStandardAction } from "flux-standard-action";
import {
  EmailOutreach,
  EmailOutreachResponse,
  PhoneOutreach,
  PhoneOutreachResponse
} from "../flight-details/model";
import { FlightListFilter } from "../flight-list/model";
import {
  CreateCustomerInput,
  Customer,
  CustomerError,
  CustomerFormData,
  UpdateCustomerInput
} from "./model";

export interface MetaData {}

// Flux-standard-action gives us stronger typing of our actions.
export type Payload =
  | Customer
  | CustomerError
  | CustomerFormData
  | string
  | CreateCustomerInput
  | UpdateCustomerInput
  | FlightListFilter
  | Sorting
  | PhoneOutreach
  | EmailOutreach
  | InquiryOutput;

export type CustomerAction<T extends Payload = Payload> = FluxStandardAction<
  string,
  T,
  MetaData
>;

export enum CustomerActionTypes {
  LOAD_CREATE_CUSTOMER_FORM_DATA = "[Customer] LOAD_CREATE_CUSTOMER_FORM_DATA",
  LOAD_CREATE_CUSTOMER_FORM_DATA_SUCCESS = "[Customer] LOAD_CREATE_CUSTOMER_FORM_DATA_SUCCESS",
  LOAD_CREATE_CUSTOMER_FORM_DATA_FAILED = "[Customer] LOAD_CREATE_CUSTOMER_FORM_DATA_FAILED",
  SAVE_CUSTOMER = "[Customer] SAVE_CUSTOMER",
  SAVE_CUSTOMER_SUCCESS = "[Customer] SAVE_CUSTOMER_SUCCESS",
  SAVE_CUSTOMER_FAILED = "[Customer] SAVE_CUSTOMER_FAILED",
  SEARCH_EXISTING_CUSTOMERS = "[Customer] SEARCH_EXISTING_CUSTOMERS",
  SEARCH_EXISTING_CUSTOMERS_SUCCESS = "[Customer] SEARCH_EXISTING_CUSTOMERS_SUCCESS",
  SEARCH_EXISTING_CUSTOMERS_FAILED = "[Customer] SEARCH_EXISTING_CUSTOMERS_FAILED",
  UPDATE_CUSTOMER = "[Customer] UPDATE_CUSTOMER",
  UPDATE_CUSTOMER_SUCCESS = "[Customer] UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_FAILED = "[Customer] UPDATE_CUSTOMER_FAILED",
  LOAD_CUSTOMER = "[Customer] LOAD_CUSTOMER",
  LOAD_CUSTOMER_SUCCESS = "[Customer] LOAD_CUSTOMER_SUCCESS",
  LOAD_CUSTOMER_FAILED = "[Customer] LOAD_CUSTOMER_FAILED",
  LOAD_CUSTOMER_FLIGHTS = "[Customer] LOAD_CUSTOMER_FLIGHTS",
  LOAD_CUSTOMER_FLIGHTS_SUCCESS = "[Customer] LOAD_CUSTOMER_FLIGHTS_SUCCESS",
  LOAD_CUSTOMER_FLIGHTS_FAILED = "[Customer] LOAD_CUSTOMER_FLIGHTS_FAILED",
  CUSTOMER_FLIGHTS_FILTER = "[Customer] CUSTOMER_FLIGHTS_FILTER",
  CUSTOMER_FLIGHTS_SORT = "[Customer] CUSTOMER_FLIGHTS_SORT",
  LOAD_CUSTOMER_PROFILE = "[Customer] LOAD_CUSTOMER_PROFILE",
  LOAD_CUSTOMER_PROFILE_SUCCESS = "[Customer] LOAD_CUSTOMER_PROFILE_SUCCESS",
  LOAD_CUSTOMER_PROFILE_FAILED = "[Customer] LOAD_CUSTOMER_PROFILE_FAILED",
  SAVE_EMAIL_OUTREACH = "[Customer] SAVE_EMAIL_OUTREACH",
  SAVE_PHONE_OUTREACH = "[Customer] SAVE_PHONE_OUTREACH",
  SAVE_OUTREACH_SUCCESS = "[Customer] SAVE_OUTREACH_SUCCESS",
  SAVE_OUTREACH_FAILED = "[Customer] SAVE_OUTREACH_FAILED",
  SAVE_CUSTOMER_AND_CREATE_INQUIRY = "[Customer] SAVE_CUSTOMER_AND_CREATE_INQUIRY",
  SAVE_CUSTOMER_AND_CREATE_INQUIRY_SUCCESS = "[Customer] SAVE_CUSTOMER_AND_CREATE_INQUIRY_SUCCESS",
  SAVE_CUSTOMER_AND_CREATE_INQUIRY_FAILED = "[Customer] SAVE_CUSTOMER_AND_CREATE_INQUIRY_FAILED"
}

@Injectable()
export class CustomerActions {
  @dispatch()
  loadFormData = (): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CREATE_CUSTOMER_FORM_DATA
  });

  loadFormDataSuccess = (payload: CustomerFormData): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CREATE_CUSTOMER_FORM_DATA_SUCCESS,
    payload
  });

  loadFormDataFailed = (error: CustomerError): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CREATE_CUSTOMER_FORM_DATA_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  saveCustomer = (payload: CreateCustomerInput): CustomerAction => ({
    type: CustomerActionTypes.SAVE_CUSTOMER,
    payload
  });

  saveCustomerSuccess = (payload: any): CustomerAction => ({
    type: CustomerActionTypes.SAVE_CUSTOMER_SUCCESS,
    payload
  });

  saveCustomerFailed = (error: CustomerError): CustomerAction => ({
    type: CustomerActionTypes.SAVE_CUSTOMER_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  searchExistingCustomer = (payload: CreateCustomerInput): CustomerAction => ({
    type: CustomerActionTypes.SEARCH_EXISTING_CUSTOMERS,
    payload
  });

  searchExistingCustomerSuccess = (payload: any): CustomerAction => ({
    type: CustomerActionTypes.SEARCH_EXISTING_CUSTOMERS_SUCCESS,
    payload
  });

  searchExistingCustomerFailed = (error: CustomerError): CustomerAction => ({
    type: CustomerActionTypes.SAVE_CUSTOMER_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  updateCustomer = (payload: Customer): CustomerAction => ({
    type: CustomerActionTypes.UPDATE_CUSTOMER,
    payload
  });

  updateCustomerSuccess = (payload: any): CustomerAction => ({
    type: CustomerActionTypes.UPDATE_CUSTOMER_SUCCESS,
    payload
  });

  updateCustomerFailed = (error: CustomerError): CustomerAction => ({
    type: CustomerActionTypes.UPDATE_CUSTOMER_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  loadCustomer = (payload: string): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CUSTOMER,
    payload
  });

  loadCustomerSuccess = (payload: Customer): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CUSTOMER_SUCCESS,
    payload
  });

  loadCustomerFailed = (error: CustomerError): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CUSTOMER_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  loadCustomerFlights = (payload: string): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CUSTOMER_FLIGHTS,
    payload
  });

  loadCustomerFlightsSuccess = (payload: InquiryOutput): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CUSTOMER_FLIGHTS_SUCCESS,
    payload
  });

  loadCustomerFlightsFailed = (error: CustomerError): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CUSTOMER_FLIGHTS_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  customerFlightsFilter = (filter: FlightListFilter): CustomerAction => ({
    type: CustomerActionTypes.CUSTOMER_FLIGHTS_FILTER,
    payload: filter
  });

  @dispatch()
  customerFlightsSort = (sorting: Sorting): CustomerAction => ({
    type: CustomerActionTypes.CUSTOMER_FLIGHTS_SORT,
    payload: sorting
  });

  @dispatch()
  loadCustomerProfile = (payload: string): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CUSTOMER_PROFILE,
    payload
  });

  loadCustomerProfileSuccess = (payload: Customer): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CUSTOMER_PROFILE_SUCCESS,
    payload
  });

  loadCustomerProfileFailed = (error: CustomerError): CustomerAction => ({
    type: CustomerActionTypes.LOAD_CUSTOMER_PROFILE_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  savePhoneOutreach = (payload: PhoneOutreach): CustomerAction => ({
    type: CustomerActionTypes.SAVE_PHONE_OUTREACH,
    payload
  });

  @dispatch()
  saveEmailOutreach = (payload: EmailOutreach): CustomerAction => ({
    type: CustomerActionTypes.SAVE_EMAIL_OUTREACH,
    payload
  });

  saveOutreachSuccess = (
    payload: EmailOutreachResponse | PhoneOutreachResponse
  ): CustomerAction => ({
    type: CustomerActionTypes.SAVE_OUTREACH_SUCCESS,
    payload
  });

  saveOutreachFailed = (error: CustomerError): CustomerAction => ({
    type: CustomerActionTypes.SAVE_OUTREACH_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  saveCustomerAndCreateInquiry = (
    payload: CreateCustomerInput
  ): CustomerAction => ({
    type: CustomerActionTypes.SAVE_CUSTOMER_AND_CREATE_INQUIRY,
    payload
  });

  saveCustomerAndCreateInquirySuccess = (payload: any): CustomerAction => ({
    type: CustomerActionTypes.SAVE_CUSTOMER_AND_CREATE_INQUIRY_SUCCESS,
    payload
  });

  saveCustomerAndCreateInquiryFailed = (
    error: CustomerError
  ): CustomerAction => ({
    type: CustomerActionTypes.SAVE_CUSTOMER_AND_CREATE_INQUIRY_FAILED,
    payload: error,
    error: true
  });
}
