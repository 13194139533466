import { Injectable } from "@angular/core";
import { NoteData } from "@flightpath/ui";
import { ToastrService } from "ngx-toastr";
import { combineEpics, Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AppState } from "../store/model";
import { NoteAction, NoteActions, NoteActionTypes } from "./actions";
import { NoteService } from "./note.service";

@Injectable()
export class NoteEpics {
  constructor(
    private service: NoteService,
    private actions: NoteActions,
    private toastr: ToastrService
  ) {}

  createEpic() {
    return combineEpics(
      this.createSaveNoteEpic(),
      this.createSaveNoteFromProfileEpic()
    );
  }

  private createSaveNoteEpic(): Epic<NoteAction, NoteAction, AppState> {
    return (action$, state$) =>
      action$.ofType(NoteActionTypes.SAVE_NOTE).pipe(
        switchMap(action =>
          this.service
            .saveNote(action.payload as NoteData, action.meta.noteType)
            .pipe(
              map(data => {
                this.toastr.success("Note saved");
                return this.actions.saveNoteSuccess(data, action.meta.noteType);
              }),
              catchError(response => {
                this.toastr.error("Failed to save note");
                return of(
                  this.actions.saveFailed({
                    status: response.status as string
                  })
                );
              })
            )
        )
      );
  }

  private createSaveNoteFromProfileEpic(): Epic<
    NoteAction,
    NoteAction,
    AppState
  > {
    return (action$, state$) =>
      action$.ofType(NoteActionTypes.SAVE_NOTE_FROM_PROFILE).pipe(
        switchMap(action =>
          this.service
            .saveNote(action.payload as NoteData, action.meta.noteType)
            .pipe(
              map(data => {
                this.toastr.success("Note saved");
                return this.actions.saveNoteFromProfileSuccess(
                  data,
                  action.meta.noteType
                );
              }),
              catchError(response => {
                this.toastr.error("Failed to save note");
                return of(
                  this.actions.saveFromProfileFailed({
                    status: response.status as string
                  })
                );
              })
            )
        )
      );
  }
}
