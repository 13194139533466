// Angular-redux ecosystem stuff.
// @angular-redux/form and @angular-redux/router are optional
// extensions that sync form and route location state between
// our store and Angular.
import { provideReduxForms } from "@angular-redux/form";
import { NgReduxRouter, NgReduxRouterModule } from "@angular-redux/router";
import {
  DevToolsExtension,
  NgRedux,
  NgReduxModule
} from "@angular-redux/store";
import { NgModule } from "@angular/core";
// Redux ecosystem stuff.
import { FluxStandardAction } from "flux-standard-action";
import { createLogger } from "redux-logger";
import { combineEpics, createEpicMiddleware, Epic } from "redux-observable";
import { NotesModule } from "../notes/notes.module";
import { RootActions } from "./actions";
// The top-level reducers and epics that make up our app's logic.
import { RootEpics } from "./epics";
import { AppState, InitialAppState } from "./model";
import { rootReducer } from "./reducers";

@NgModule({
  imports: [NgReduxModule, NgReduxRouterModule.forRoot(), NotesModule],
  providers: [RootEpics, InitialAppState, RootActions]
})
export class StoreModule {
  constructor(
    public store: NgRedux<AppState>,
    devTools: DevToolsExtension,
    ngReduxRouter: NgReduxRouter,
    rootEpics: RootEpics,
    initialAppState: InitialAppState
  ) {
    // Tell Redux about our reducers and epics. If the Redux DevTools
    // chrome extension is available in the browser, tell Redux about
    // it too.
    const epicMiddleware = createEpicMiddleware<
      FluxStandardAction<any, any>,
      FluxStandardAction<any, any>,
      AppState
    >();

    store.configureStore(
      rootReducer,
      initialAppState.createState(),
      [createLogger(), epicMiddleware],
      // configure store typings conflict with devTools typings
      (devTools.isEnabled() ? [devTools.enhancer()] : []) as any
    );

    rootEpics.epics$.subscribe(
      (epics: Array<Epic<any, any, any, any>>): void => {
        epicMiddleware.run(combineEpics(...epics));
      }
    );

    // Enable syncing of Angular router state with our Redux store.
    if (ngReduxRouter) {
      ngReduxRouter.initialize();
    }

    // Enable syncing of Angular form state with our Redux store.
    provideReduxForms(store);
  }
}
