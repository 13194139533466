import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, NG_VALIDATORS } from "@angular/forms";
import { FlightPathUIModule } from "@flightpath/ui";
import { AuthModule } from "../auth/auth.module";
import { ForgottenPasswordComponent } from "./forgotten-password.component";
import { LoginComponent } from "./login.component";
import { LoginGuard } from "./login.guard";
import { EmailValidatorDirective, PasswordValidatorDirective } from "./login.validators";

@NgModule({
  declarations: [
    LoginComponent,
    ForgottenPasswordComponent,
    EmailValidatorDirective,
    PasswordValidatorDirective
  ],
  imports: [CommonModule, FormsModule, AuthModule, FlightPathUIModule],
  providers: [
    LoginGuard,
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidatorDirective,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordValidatorDirective,
      multi: true
    }
  ]
})
export class LoginModule {}
