import { select } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { User } from "./model";

@Injectable()
export class AuthGuard implements CanActivate {
  @select(["auth", "user"])
  readonly user!: Observable<User>;

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.user.pipe(
      switchMap(user => {
        if (user) {
          return of(true);
        }

        sessionStorage.setItem("redirectUrl", state.url);
        this.router.navigate(["login"]);
        return of(false);
      })
    );
  }
}
