import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthActions } from "../auth/actions";
import { LoaderService } from "./loader.service";

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(
    private readonly auth: AuthActions,
    private readonly loaderService: LoaderService,
    private toastr: ToastrService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiReq = req.clone({
      url: `${environment.apiUrl}/${req.url}`
    });
    return next.handle(apiReq).pipe(
      catchError((err: HttpErrorResponse) => {
        this.loaderService.hide();
        this.toastr.error("Error");
        // if (environment.production) this.auth.logout();
        return throwError(err);
      })
    );
  }
}
