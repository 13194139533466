import { Injectable } from "@angular/core";
import { combineEpics, Epic } from "redux-observable";
import { BehaviorSubject } from "rxjs";
import { AuthEpics } from "../auth/epics";
import { NoteEpics } from "../notes/epics";

@Injectable()
export class RootEpics {
  epics: Array<Epic> = [];
  epics$: BehaviorSubject<Array<Epic>> = new BehaviorSubject(this.epics);

  constructor(private authEpics: AuthEpics, private noteEpics: NoteEpics) {
    this.addEpic(
      combineEpics(this.authEpics.createEpic(), this.noteEpics.createEpic())
    );
  }

  addEpic(epic: Epic) {
    this.epics$.next([...this.epics, epic]);
  }
}
