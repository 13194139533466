import { Flight } from "@flightpath/api-types";
import { Sorting, SortType } from "@flightpath/ui";
import { NoteAction, NoteActionTypes } from "../notes/actions";
import { FlightDetailsAction, FlightDetailsActionTypes } from "./actions";
import { AlertOptions, FlightDetailsState, GetPricingResponse, PricingOption, pricingState } from "./model";

const INITIAL_PRICING_STATE: pricingState = {
  id: null,
  inquiryId: null,
  options: null,
  loading: false,
  selectedOptionIds: [],
  sorting: {
    field: "number",
    direction: 1,
    type: SortType.Text
  },
  optionDetails: {
    optionData: {},
    tailData: {}
  }
};

const INITIAL_QUOTES_STATE: any = {
  quotes: null,
  selectedQuotes: []
};

const INITIAL_STATE: FlightDetailsState = {
  pricing: INITIAL_PRICING_STATE,
  receivedQuotes: INITIAL_QUOTES_STATE,
  details: null,
  loading: false,
  saving: false,
  error: undefined
};

function defaultPricingSort(a: PricingOption, b: PricingOption) {
  return (
    ((a.tail.number as string) || "")
      .toString()
      .localeCompare(((b.tail.number as string) || "").toString()) * -1
  );
}

function PricingSorter(sorting: Sorting) {
  if (!sorting) return defaultPricingSort;
  return function(a: PricingOption, b: PricingOption) {
    switch (sorting.type) {
      case SortType.Text:
        return (
          (
            (a[sorting.field] as string) ||
            (a.tail[sorting.field] as string) ||
            ""
          )
            .toString()
            .localeCompare(
              (
                (b[sorting.field] as string) ||
                (b.tail[sorting.field] as string) ||
                ""
              ).toString()
            ) * sorting.direction
        );
      default:
        return 1;
    }
  };
}

function handleOptionSelection(selectedOptions: number[], optionsId: number) {
  let selected = [];
  if (selectedOptions.includes(optionsId)) {
    selected = selectedOptions.filter(option => option !== optionsId);
  } else {
    selected = [...selectedOptions, optionsId];
  }
  return selected;
}

function handleQuoteSelection(selectedQuotes: any[], quote: any) {
  let selected = [];
  if (selectedQuotes.some(q => q.id === quote.id)) {
    selected = selectedQuotes.filter(q => q.id !== quote.id);
  } else {
    selected = [...selectedQuotes, quote];
  }
  return selected;
}

export function flightDetailsReducer(
  state = INITIAL_STATE,
  action: FlightDetailsAction | NoteAction
): FlightDetailsState {
  switch (action.type) {
    case FlightDetailsActionTypes.LOAD:
      return {
        ...state,
        loading:
          !state.details ||
          (state.details &&
            state.details.inquiry.id !=
              (action as FlightDetailsAction).meta.referenceId),
        error: undefined
      };
    case FlightDetailsActionTypes.LOAD_SUCCESS:
      return {
        pricing: INITIAL_PRICING_STATE,
        receivedQuotes: INITIAL_QUOTES_STATE,
        details: action.payload as Flight,
        loading: false,
        saving: false,
        error: false
      };
    case FlightDetailsActionTypes.LOAD_FAILED:
      return {
        pricing: INITIAL_PRICING_STATE,
        receivedQuotes: INITIAL_QUOTES_STATE,
        details: null,
        loading: false,
        saving: false,
        error: action.error
      };
    case NoteActionTypes.SAVE_NOTE_SUCCESS:
      return {
        ...state,
        /*         details: {
          ...state.details,
          [(action as NoteAction).meta.noteType]: [
            ...state.details[(action as NoteAction).meta.noteType],
            action.payload
          ]
        }, */
        saving: false
      };
    case FlightDetailsActionTypes.SAVE_OUTREACH_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details
          /*           InquiryContactEvents: [
            ...state.details.InquiryContactEvents,
            action.payload as any
          ] */
        },
        saving: false
      };
    case FlightDetailsActionTypes.SAVE_ALERT_OPTIONS_SUCCESS:
      const {
        TenderSubmitted,
        TenderReceived
      } = action.payload as AlertOptions;
      return {
        ...state,
        /*         details: {
          ...state.details,
          AlertTenderSubmitted: TenderSubmitted ? 1 : 0,
          AlertTenderReceived: TenderReceived ? 1 : 0
        }, */
        saving: false
      };
    case FlightDetailsActionTypes.SAVE_FAILED:
      return {
        ...state,
        saving: false,
        error: action.error
      };
    case FlightDetailsActionTypes.LOAD_PRICING:
      return {
        ...state,
        pricing: {
          ...state.pricing,
          loading: true,
          inquiryId: action.payload as string
        }
      };
    case FlightDetailsActionTypes.LOAD_PRICING_FAILED:
      return {
        ...state,
        pricing: INITIAL_PRICING_STATE,
        error: action.error
      };
    case FlightDetailsActionTypes.LOAD_PRICING_SUCCESS:
      return {
        ...state,
        pricing: {
          ...state.pricing,
          loading: false,
          ...(action.payload as GetPricingResponse)
        }
      };
    case FlightDetailsActionTypes.SORT_PRICING: {
      const sorting = {
        ...(action.payload as Sorting)
      };
      const sortedOptions = state.pricing.options.sort(PricingSorter(sorting));
      return {
        ...state,
        pricing: {
          ...state.pricing,
          options: sortedOptions,
          sorting: sorting
        }
      };
    }
    case FlightDetailsActionTypes.CHANGE_SELECTED_PRICING_OPTION: {
      const optionId = action.payload as number;
      const selectedOptions = state.pricing.selectedOptionIds;
      const newSelectedOptions = handleOptionSelection(
        selectedOptions,
        optionId
      );
      return {
        ...state,
        pricing: {
          ...state.pricing,
          selectedOptionIds: newSelectedOptions
        }
      };
    }
    case FlightDetailsActionTypes.CREATE_RFQ_FOR_ITINERARY_OPTIONS:
      return {
        ...state,
        loading: true
      };
    case FlightDetailsActionTypes.CREATE_RFQ_FOR_ITINERARY_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        pricing: {
          ...state.pricing,
          ...(action.payload as any)
        }
      };
    case FlightDetailsActionTypes.CREATE_RFQ_FOR_ITINERARY_OPTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case FlightDetailsActionTypes.LOAD_OPERATOR_DETAILS:
      return {
        ...state,
        pricing: {
          ...state.pricing,
          optionDetails: { optionData: { ...(action.payload as any) } }
        }
      };
    case FlightDetailsActionTypes.LOAD_TAIL_DETAILS_SUCCESS:
      return {
        ...state,
        pricing: {
          ...state.pricing,
          optionDetails: {
            ...state.pricing.optionDetails,
            tailData: { ...(action.payload as any) }
          }
        }
      };
    case FlightDetailsActionTypes.LOAD_TAIL_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case FlightDetailsActionTypes.LOAD_RECEIVED_QUOTES:
      return {
        ...state,
        loading: true
      };
    case FlightDetailsActionTypes.LOAD_RECEIVED_QUOTES_SUCCESS:
      return {
        ...state,
        receivedQuotes: {
          quotes: [...(action.payload as any)],
          selectedQuotes: []
        },
        loading: false
      };
    case FlightDetailsActionTypes.LOAD_RECEIVED_QUOTES_FAILED:
      return {
        ...state,
        receivedQuotes: INITIAL_QUOTES_STATE,
        loading: false,
        error: action.error
      };
    case FlightDetailsActionTypes.CHANGE_SELECTED_QUOTE: {
      const quote = action.payload as any;
      const selectedQuotes = state.receivedQuotes.selectedQuotes;
      const newSelectedQuotes = handleQuoteSelection(selectedQuotes, quote);
      return {
        ...state,
        receivedQuotes: {
          ...state.receivedQuotes,
          selectedQuotes: newSelectedQuotes
        }
      };
    }
  }

  return state;
}
