import { Component } from "@angular/core";

@Component({
  templateUrl: "./forgotten-password.component.html",
  styleUrls: ["./login.component.scss"]
})
export class ForgottenPasswordComponent {
  email: string = "";
  formIsSubmitted: boolean = false;

  constructor() {}

  save(form) {
    this.formIsSubmitted = true;
    if (form.valid) {
      console.log("asdasd");
    }
  }
}
