import { dispatch } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { InquiryOutput } from "@flightpath/api-types";
import { Sorting } from "@flightpath/ui";
import { FluxStandardAction } from "flux-standard-action";
import { FlightList, FlightListError, FlightListFilter } from "./model";

export interface MetaData {
  isBackground: boolean;
}

// Flux-standard-action gives us stronger typing of our actions.
export type Payload =
  | FlightList
  | FlightListError
  | FlightListFilter
  | Sorting
  | InquiryOutput[]
  | InquiryOutput;

export type FlightListAction<T extends Payload = Payload> = FluxStandardAction<
  string,
  T,
  MetaData
>;

export enum FlightListActionTypes {
  LOAD = "[FlightList] LOAD",
  LOAD_SUCCESS = "[FlightList] LOAD_SUCCESS",
  LOAD_FAILED = "[FlightList] LOAD_FAILED",
  LOAD_CANCEL = "[FlightList] LOAD_CANCEL",
  FILTER = "[FlightList] FILTER",
  FILTER_TOGGLE = "[FlightList] FILTER_TOGGLE",
  SORT = "[FlightList] SORT"
}

@Injectable()
export class FlightListActions {
  @dispatch()
  load = (isBackground: boolean = false): FlightListAction => ({
    type: FlightListActionTypes.LOAD,
    meta: { isBackground }
  });

  loadBackground = (): FlightListAction => ({
    type: FlightListActionTypes.LOAD,
    meta: { isBackground: true }
  });

  loadSuccess = (payload: InquiryOutput[]): FlightListAction => ({
    type: FlightListActionTypes.LOAD_SUCCESS,
    payload
  });

  loadFailed = (error: FlightListError): FlightListAction => ({
    type: FlightListActionTypes.LOAD_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  loadCancel = (): FlightListAction => ({
    type: FlightListActionTypes.LOAD_CANCEL
  });

  @dispatch()
  filter = (filter: FlightListFilter): FlightListAction => ({
    type: FlightListActionTypes.FILTER,
    payload: filter
  });

  @dispatch()
  filterToggle = (): FlightListAction => ({
    type: FlightListActionTypes.FILTER_TOGGLE
  });

  @dispatch()
  sort = (sorting: Sorting): FlightListAction => ({
    type: FlightListActionTypes.SORT,
    payload: sorting
  });
}
