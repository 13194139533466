import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NoteActions } from "./actions";
import { NoteEpics } from "./epics";
import { NoteService } from "./note.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [NoteActions, NoteEpics, NoteService]
})
export class NotesModule {}
