import { dispatch } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { FluxStandardAction } from "flux-standard-action";
import { Auth, AuthError, User } from "./model";

export interface MetaData {
  username: string;
  password: string;
}

// Flux-standard-action gives us stronger typing of our actions.
export type Payload = Auth | AuthError | User;

export type AuthAction<T extends Payload = Payload> = FluxStandardAction<
  string,
  T,
  MetaData
>;

@Injectable()
export class AuthActions {
  static readonly LOGIN = "[Auth] LOGIN";
  static readonly LOGIN_SUCCESS = "[Auth] LOGIN_SUCCESS";
  static readonly LOGIN_FAILED = "[Auth] LOGIN_FAILED";
  static readonly LOGOUT = "[Auth] LOGOUT";

  @dispatch()
  login = (username, password): AuthAction => ({
    type: AuthActions.LOGIN,
    meta: { username, password }
  });

  @dispatch()
  logout = (): AuthAction => ({
    type: AuthActions.LOGOUT
  });

  loginSuccess = (user: User): AuthAction => ({
    type: AuthActions.LOGIN_SUCCESS,
    payload: user
  });

  loginFailed = (error: AuthError): AuthAction => ({
    type: AuthActions.LOGIN_FAILED,
    payload: error,
    error: true
  });
}
