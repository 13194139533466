import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { tokenGetter } from "./service";

export interface User {
  token?: string;
}

export interface Auth {
  user: User;
  loading: boolean;
  error: boolean;
  locked: boolean;
}

export interface AuthError {
  status?: number;
  locked?: boolean;
}

@Injectable()
export class InitialAuthState {
  constructor(private jwtHelper: JwtHelperService) {}

  createState() {
    let user: User = null;
    const token = tokenGetter();
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      user = { token };
    }
    return {
      user,
      loading: false,
      error: false,
      locked: false
    };
  }
}
