import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Note, NoteData } from '@flightpath/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NoteResponse, NoteType } from './model';

const saveNotePath = {
  [NoteType.CustomerNote]: 'flight/customer-notes',
  [NoteType.TripNote]: 'flight/inquiry-notes'
};

@Injectable()
export class NoteService {
  constructor(private http: HttpClient) {}

  saveNote = (data: NoteData, noteType: NoteType): Observable<Note> => {
    return this.http.post<NoteResponse>(saveNotePath[noteType], data).pipe(map((res) => res.result));
  };
}
