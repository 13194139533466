import { AuthAction, AuthActions } from "./actions";
import { Auth, AuthError, User } from "./model";

const INITIAL_STATE: Auth = {
  user: null,
  loading: false,
  error: undefined,
  locked: undefined
};

export function authReducer(
  state: Auth = INITIAL_STATE,
  action: AuthAction
): Auth {
  switch (action.type) {
    case AuthActions.LOGIN:
      return {
        ...state,
        loading: true,
        error: undefined,
        locked: undefined
      };
    case AuthActions.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload as User,
        loading: false,
        error: undefined,
        locked: undefined
      };
    case AuthActions.LOGIN_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
        error: action.error,
        locked: (action.payload as AuthError).locked
      };
    case AuthActions.LOGOUT:
      return {
        ...INITIAL_STATE
      };
  }

  return state;
}
