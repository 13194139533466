import { dispatch } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { Flight } from "@flightpath/api-types";
import { Note, NoteData, Sorting } from "@flightpath/ui";
import { FluxStandardAction } from "flux-standard-action";
import { AlertOptions, CreateRFQForItineraryOptions, EmailOutreach, EmailOutreachResponse, FlightDetailsError, PhoneOutreach, PhoneOutreachResponse, Pricing } from "./model";

export interface MetaData {
  referenceId?: string;
}

// Flux-standard-action gives us stronger typing of our actions.
export type Payload =
  | NoteData
  | Note
  | EmailOutreach
  | EmailOutreachResponse
  | PhoneOutreach
  | PhoneOutreachResponse
  | AlertOptions
  | FlightDetailsError
  | Pricing
  | string
  | number
  | Flight;

export type FlightDetailsAction<
  T extends Payload = Payload
> = FluxStandardAction<string, T, MetaData>;

export enum FlightDetailsActionTypes {
  DETAILS = "[FlightDetails] DETAILS",
  LOAD = "[FlightDetails] LOAD",
  LOAD_SUCCESS = "[FlightDetails] LOAD_SUCCESS",
  LOAD_FAILED = "[FlightDetails] LOAD_FAILED",
  SAVE_EMAIL_OUTREACH = "[FlightDetails] SAVE_EMAIL_OUTREACH",
  SAVE_PHONE_OUTREACH = "[FlightDetails] SAVE_PHONE_OUTREACH",
  SAVE_OUTREACH_SUCCESS = "[FlightDetails] SAVE_OUTREACH_SUCCESS",
  SAVE_ALERT_OPTIONS = "[FlightDetails] SAVE_ALERT_OPTIONS",
  SAVE_ALERT_OPTIONS_SUCCESS = "[FlightDetails] SAVE_ALERT_OPTIONS_SUCCESS",
  SAVE_FAILED = "[FlightDetails] SAVE_FAILED",
  LOAD_PRICING = "[FlightDetails] LOAD_PRICING",
  LOAD_PRICING_SUCCESS = "[FlightDetails] LOAD_PRICING_SUCCESS",
  LOAD_PRICING_FAILED = "[FlightDetails] LOAD_PRICING_FAILED",
  SORT_PRICING = "[FlightDetails] SORT_PRICING",
  CHANGE_SELECTED_PRICING_OPTION = "[FlightDetails] CHANGE_SELECTED_PRICING_OPTION",
  CREATE_RFQ_FOR_ITINERARY_OPTIONS = "[FlightDetails] CREATE_RFQ_FOR_ITINERARY_OPTIONS",
  CREATE_RFQ_FOR_ITINERARY_OPTIONS_SUCCESS = "[FlightDetails] CREATE_RFQ_FOR_ITINERARY_OPTIONS_SUCCESS",
  CREATE_RFQ_FOR_ITINERARY_OPTIONS_FAILED = "[FlightDetails] CREATE_RFQ_FOR_ITINERARY_OPTIONS_FAILED",
  LOAD_OPERATOR_DETAILS = "[FlightDetails] LOAD_OPERATOR_DETAILS",
  LOAD_TAIL_DETAILS = "[FlightDetails] LOAD_TAIL_DETAILS",
  LOAD_TAIL_DETAILS_SUCCESS = "[FlightDetails] LOAD_TAIL_DETAILS_SUCCESS",
  LOAD_TAIL_DETAILS_FAILED = "[FlightDetails] LOAD_TAIL_DETAILS_FAILED",
  SEND_QUOTE_EMAIL = "[FlightDetails] SEND_QUOTE_EMAIL",
  SEND_QUOTE_EMAIL_SUCCESS = "[FlightDetails] SEND_QUOTE_EMAIL_SUCCESS",
  SEND_QUOTE_EMAIL_FAILED = "[FlightDetails] SEND_QUOTE_EMAIL_FAILED",
  LOAD_RECEIVED_QUOTES = "[FlightDetails] LOAD_RECEIVED_QUOTES",
  LOAD_RECEIVED_QUOTES_FROM_PAGE = "[FlightDetails] LOAD_RECEIVED_QUOTES_FROM_PAGE",
  LOAD_RECEIVED_QUOTES_SUCCESS = "[FlightDetails] LOAD_RECEIVED_QUOTES_SUCCESS",
  LOAD_RECEIVED_QUOTES_FAILED = "[FlightDetails] LOAD_RECEIVED_QUOTES_FAILED",
  CHANGE_SELECTED_QUOTE = "[FlightDetails] CHANGE_SELECTED_QUOTE"
}

@Injectable()
export class FlightDetailsActions {
  @dispatch()
  load = (referenceId: string): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD,
    meta: { referenceId }
  });

  loadSuccess = (payload: Flight): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_SUCCESS,
    payload
  });

  loadFailed = (error: FlightDetailsError): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  savePhoneOutreach = (payload: PhoneOutreach): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.SAVE_PHONE_OUTREACH,
    payload
  });

  @dispatch()
  saveEmailOutreach = (payload: EmailOutreach): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.SAVE_EMAIL_OUTREACH,
    payload
  });

  saveOutreachSuccess = (
    payload: EmailOutreachResponse | PhoneOutreachResponse
  ): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.SAVE_OUTREACH_SUCCESS,
    payload
  });

  @dispatch()
  saveAlertOptions = (payload: AlertOptions): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.SAVE_ALERT_OPTIONS,
    payload
  });

  saveAlertOptionsSuccess = (payload: AlertOptions): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.SAVE_ALERT_OPTIONS_SUCCESS,
    payload
  });

  saveFailed = (error: FlightDetailsError): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.SAVE_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  loadPricing = (payload: string): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_PRICING,
    payload
  });

  loadPricingSuccess = (payload: Pricing): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_PRICING_SUCCESS,
    payload
  });

  loadPricingFailed = (error: FlightDetailsError): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_PRICING_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  sortPricing = (sorting: Sorting): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.SORT_PRICING,
    payload: sorting
  });

  @dispatch()
  createRFQForItineraryOptions = (
    payload: CreateRFQForItineraryOptions
  ): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.CREATE_RFQ_FOR_ITINERARY_OPTIONS,
    payload
  });

  createRFQForItineraryOptionsSuccess = (
    payload: any
  ): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.CREATE_RFQ_FOR_ITINERARY_OPTIONS_SUCCESS,
    payload
  });

  createRFQForItineraryOptionsFailed = (
    error: FlightDetailsError
  ): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.CREATE_RFQ_FOR_ITINERARY_OPTIONS_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  changeSelectedPricingOptions = (optionId: number): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.CHANGE_SELECTED_PRICING_OPTION,
    payload: optionId
  });

  @dispatch()
  loadOperatorDetails = (item: any): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_OPERATOR_DETAILS,
    payload: item
  });

  loadTailDetails = (tailNumber: any): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_TAIL_DETAILS,
    payload: tailNumber
  });

  loadTailDetailsSuccess = (payload: any): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_TAIL_DETAILS_SUCCESS,
    payload
  });

  loadTailDetailsFailed = (error: FlightDetailsError): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_TAIL_DETAILS_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  sendQuoteEmail = (email: any): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.SEND_QUOTE_EMAIL,
    payload: email
  });

  sendQuoteEmailSuccess = (payload: any): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.SEND_QUOTE_EMAIL_SUCCESS,
    payload
  });

  sendQuoteEmailFailed = (error: FlightDetailsError): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.SEND_QUOTE_EMAIL_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  loadReceivedQuotes = (payload: any): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_RECEIVED_QUOTES,
    payload
  });

  @dispatch()
  loadReceivedQuotesFromPage = (payload: any): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_RECEIVED_QUOTES_FROM_PAGE,
    payload
  });

  loadReceivedQuotesSuccess = (payload: any): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_RECEIVED_QUOTES_SUCCESS,
    payload
  });

  loadReceivedQuotesFailed = (
    error: FlightDetailsError
  ): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.LOAD_RECEIVED_QUOTES_FAILED,
    payload: error,
    error: true
  });

  @dispatch()
  changeSelectedQuotes = (quote: any): FlightDetailsAction => ({
    type: FlightDetailsActionTypes.CHANGE_SELECTED_QUOTE,
    payload: quote
  });
}
