import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { select } from "@angular-redux/store";
import { Observable, of } from "rxjs";
import { User } from "../auth/model";
import { switchMap } from "rxjs/operators";

@Injectable()
export class LoginGuard implements CanActivate {
  @select(["auth", "user"])
  readonly user!: Observable<User>;

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.user.pipe(
      switchMap(user => {
        if (!user) {
          return of(true);
        }

        this.router.navigate(["flight-list"]);
        return of(false);
      })
    );
  }
}
