import { dispatch } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { FluxStandardAction } from "flux-standard-action";

export interface MetaData {}

// Flux-standard-action gives us stronger typing of our actions.
export type Payload = any;

export type RootAction<T extends Payload = Payload> = FluxStandardAction<
  string,
  T,
  MetaData
>;

export enum RootActionTypes {
  ROUTE_DATA = "[Root] ROUTE_DATA",
  ROUTE_PARAMS = "[Root] ROUTE_PARAMS"
}

@Injectable()
export class RootActions {
  @dispatch()
  setRouteData = (payload: any): RootAction => ({
    type: RootActionTypes.ROUTE_DATA,
    payload
  });

  @dispatch()
  setRouteParams = (payload: any): RootAction => ({
    type: RootActionTypes.ROUTE_PARAMS,
    payload
  });
}
